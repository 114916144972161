<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import { ref, markRaw, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'AppLayout',
  setup() {
    const layout = ref();
    const route = useRoute();

    const getLayout = async (lyt) => {
      if (lyt === 'AppLayoutDefault') {
        const c = await import('@/layouts/AppLayoutDefault');
        return c.default;
      }
      if (lyt === 'AppLayoutLight') {
        const c = await import('@/layouts/AppLayoutLight');
        return c.default;
      }
      if (lyt === 'AppLayoutRaw') {
        const c = await import('@/layouts/AppLayoutRaw');
        return c.default;
      }
      if (lyt === 'AppLayoutSidebar') {
        const c = await import('@/layouts/AppLayoutSidebar');
        return c.default;
      }
      throw new Error();
    };

    watch(
      () => route.meta,
      async (meta) => {
        try {
          layout.value = markRaw(await getLayout(meta.layout));
        } catch (e) {
          layout.value = markRaw(await getLayout('AppLayoutDefault'));
        }
      },
      { immediate: true },
    );
    return { layout };
  },
};
</script>
