<template>
<transition name="e-modal" :duration="300">
  <aside
    v-if="isOpen"
    class="e-modal"
    :class="{ 'e-modal__full': isFull }">
    <OnClickOutside
      @trigger="closeModal"
      class="e-modal--dialog"
      >
      <header v-if="title" class="e-modal--header pb6">
        <h2 class="mr4">{{ title }}</h2>
        <icon-cross-light
          width="20"
          height="20"
          class="c-pointer fc-light-400"
          @click="closeModal"
          />
      </header>
      <component :is="view" v-model="model"></component>
      <footer v-if="!isFull && actions && actions.length" class="e-modal--footer pt6">
        <button
          v-for="action in actions"
          v-bind:key="action.label"
          class="e-btn"
          :class="action.class"
          @click="action.callback(model)"
          >
          <component v-if="action.icon" :is="action.icon"></component>
          {{ action.label }}
        </button>
      </footer>
    </OnClickOutside>
  </aside>
</transition>
</template>

<script setup>
import { OnClickOutside } from '@vueuse/components';
import { reactive } from 'vue';
import { storeToRefs } from 'pinia';
import useModalStore from '@/stores/modal';
import IconCrossLight from '@/components/icons/IconCrossLight';

const modal = useModalStore();
const model = reactive({});
const {
  isOpen, isFull, view, actions, title,
} = storeToRefs(modal);

const closeModal = () => {
  modal.close();
};
</script>
