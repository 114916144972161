import { defineStore } from 'pinia';
import axios from 'axios';
import useIamStore from '@/stores/iam';
import useNotificationStore from '@/stores/notification';

export default defineStore('users', {
  state: () => ({
    users: {}, requests: {}, properties: {}, acl: {},
  }),
  actions: {
    async fetchUsers(propertyId, force = false) {
      try {
        if (this.properties[propertyId] && !force) {
          return this.properties[propertyId];
        }
        const store = useIamStore();
        const accessToken = store.getAccessToken;
        const res = await axios.get(
          `${process.env.VUE_APP_IAM_API}/v1/properties/${propertyId}/relationships/acls/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        this.properties[propertyId] = res.data;
      } catch (error) {
        console.error(error);
      }
      return true;
    },
    async fetchAcl(propertyId, userId, force = false) {
      try {
        if (this.acl[propertyId] && this.acl[propertyId][userId] && !force) {
          return this.acl[propertyId][userId];
        }
        const store = useIamStore();
        const accessToken = store.getAccessToken;
        const res = await axios.get(
          `${process.env.VUE_APP_IAM_API}/v1/properties/${propertyId}/relationships/acls/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        this.acl[propertyId] = this.acl[propertyId] || {};
        this.acl[propertyId][userId] = res.data.data.attributes.acl;
      } catch (error) {
        console.error(error);
      }
      return true;
    },
    async fetchUser(id) {
      try {
        if (this.users[id]) {
          return true;
        }
        if (this.requests[id]) {
          await this.requests[id];
          return true;
        }
        const store = useIamStore();
        const accessToken = store.getAccessToken;
        this.requests[id] = axios.get(
          `${process.env.VUE_APP_IAM_API}/v1/users/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        const res = await this.requests[id];
        this.users[id] = res.data.data;
        delete this.requests[id];
      } catch (error) {
        console.error(error);
      }
      return true;
    },
    async updateUser(propertyId, id, data) {
      const notificationStore = useNotificationStore();
      try {
        const store = useIamStore();
        const accessToken = store.getAccessToken;
        const res = await axios.patch(
          `${process.env.VUE_APP_IAM_API}/v1/properties/${propertyId}/relationships/acls/${id}`,
          { data },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        if (this.properties[propertyId]) {
          const idx = this.properties[propertyId].data.findIndex((item) => item.id === id);
          this.properties[propertyId].data[idx] = res.data;
        }
        notificationStore.notify({
          title: 'Edited',
          text: 'Your user has been updated',
          type: 'success',
        });
        return res.data;
      } catch (error) {
        console.error(error);
        notificationStore.notify({
          title: error.response.data.errors[0].title,
          text: error.response.data.errors[0].detail,
          type: 'error',
        });
        return null;
      }
    },
    async createUser(propertyId, data) {
      const notificationStore = useNotificationStore();
      try {
        const store = useIamStore();
        const accessToken = store.getAccessToken;
        const res = await axios.post(
          `${process.env.VUE_APP_IAM_API}/v1/properties/${propertyId}/relationships/acls/`,
          { data: { attributes: { acl: data.attributes.acl }, id: data.id } },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        if (this.properties[propertyId]) {
          this.properties[propertyId].data.push(res.data);
        }
        notificationStore.notify({
          title: 'Created',
          text: 'Your user has been created',
          type: 'success',
        });
        return res.data;
      } catch (error) {
        console.error(error);
        notificationStore.notify({
          title: error.response.data.errors[0].title,
          text: error.response.data.errors[0].detail,
          type: 'error',
        });
        return null;
      }
    },
    async deleteUser(propertyId, id) {
      const notificationStore = useNotificationStore();
      try {
        const store = useIamStore();
        const accessToken = store.getAccessToken;
        await axios.delete(
          `${process.env.VUE_APP_IAM_API}/v1/properties/${propertyId}/relationships/acls/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        if (this.properties[propertyId]) {
          const idx = this.properties[propertyId].data.findIndex((item) => item.id === id);
          this.properties[propertyId].data.splice(idx, 1);
        }
        notificationStore.notify({
          title: 'Deleted',
          text: 'Your user has been deleted',
          type: 'success',
        });
        return true;
      } catch (error) {
        console.error(error);
        notificationStore.notify({
          title: error.response.data.errors[0].title,
          text: error.response.data.errors[0].detail,
          type: 'error',
        });
        return false;
      }
    },
  },
  getters: {
    getUsers: (state) => (propertyId) => state.properties[propertyId] || { data: [] },
    getUser: (state) => (id) => state.users[id] || null,
    getUserOfProperty: (state) => ((propertyId, userId) => {
      if (!state.properties[propertyId]) {
        return null;
      }
      return {
        data: state.properties[propertyId].data.find((u) => u.id === userId),
        included: state.properties[propertyId].included,
      };
    }),
    getAcl: (state) => ((propertyId, userId) => {
      if (!state.acl[propertyId]) {
        return null;
      }
      return state.acl[propertyId][userId] || 'editor';
    }),
  },
});
