export default {
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Enodo Applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enodo Applications"])},
  "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "Help Center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
  "Select an organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an organization"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "Posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts"])},
  "Media Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Library"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "Audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
  "Author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
  "Brief notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief notes"])},
  "Brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief"])},
  "Canonical & hreflang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canonical & hreflang"])},
  "Canonical URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canonical URL"])},
  "Caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caption"])},
  "Clear format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear format"])},
  "Click to add audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to add audio"])},
  "Click to add image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to add image"])},
  "Click to add media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to add media"])},
  "Click to add video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to add video"])},
  "Credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credits"])},
  "Credits:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credits:"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "Edit keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit keywords"])},
  "Edit notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit notes"])},
  "Edit options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit options"])},
  "Embed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embed"])},
  "Enter canonical URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter canonical URL"])},
  "Enter hreflang URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter hreflang URL"])},
  "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "Featured image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Featured image"])},
  "Gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
  "HTML Embed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML Embed"])},
  "Headings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headings"])},
  "Hreflang URLs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hreflang URLs"])},
  "Iframe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iframe"])},
  "Keyphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyphrase"])},
  "Keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
  "Links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
  "List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
  "Media Gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Gallery"])},
  "Movie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movie"])},
  "New hreflang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New hreflang"])},
  "No hreflang added yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hreflang added yet"])},
  "No keyphrase defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No keyphrase defined"])},
  "No keywords defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No keywords defined"])},
  "No notes available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notes available"])},
  "Not synchronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not synchronized"])},
  "Ordered li.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered li."])},
  "Page break": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page break"])},
  "Paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paragraph"])},
  "Paragraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paragraphs"])},
  "Picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picture"])},
  "Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "Publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
  "Quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote"])},
  "Quoted from (URL)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quoted from (URL)"])},
  "Replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace"])},
  "Reversed li.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversed li."])},
  "Revisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisions"])},
  "SEO score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEO score"])},
  "SEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEO"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "Saving...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving..."])},
  "Select locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select locale"])},
  "Selected item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected item"])},
  "Source title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source title"])},
  "Synchronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronized"])},
  "Title 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title 2"])},
  "Title 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title 3"])},
  "Title 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title 4"])},
  "Title 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title 5"])},
  "Title 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title 6"])},
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "Tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
  "Well done, everything seems ok!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well done, everything seems ok!"])},
  "Words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Words"])},
  "character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["character"])},
  "characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["characters"])},
  "word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["word"])},
  "words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["words"])},
  "import x medias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Import medias"]), _normalize(["Import ", _interpolate(_named("n")), " media"]), _normalize(["Import ", _interpolate(_named("n")), " medias"])])},
  "x medias have been imported": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No media has been imported"]), _normalize(["One media has been imported"]), _normalize([_interpolate(_named("n")), " medias have been imported"])])},
  "Keyphrase 'keyphrase' must be in title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keyphrase '", _interpolate(_named("keyphrase")), "' must be in title"])},
  "Keyphrase 'keyphrase' must be in description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keyphrase '", _interpolate(_named("keyphrase")), "' must be in description"])},
  "n keywords are present in paragraph": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No keywords are present in paragraph"]), _normalize([_interpolate(_named("n")), " keyword is present in paragraph"]), _normalize([_interpolate(_named("n")), " keywords are present in paragraph"])])},
  "No enough titles (<n titles)": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No enough titles (<", _interpolate(_named("n")), " title)"]), _normalize(["No enough titles (<", _interpolate(_named("n")), " titles)"])])},
  "No enough words (<n words)": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No enough words (<", _interpolate(_named("n")), " words)"])},
  "Too much words (>n words)": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Too much words (>", _interpolate(_named("n")), " words)"])},
  "Publish on :site": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Publish on ", _interpolate(_named("siteName"))])},
  "Last edit: :date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last edit: ", _interpolate(_named("date"))])},
  "Bullet list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bullet list"])},
  "Bullet list /short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bullet list"])},
  "Ordered list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered list"])},
  "Reversed list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversed list"])},
  "Privacy policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "Terms of service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of service"])},
  "Do nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do nothing"])},
  "Publish now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish now"])},
  "Your article is published, would you like to post your modifications online?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your article is published, would you like to post your modifications online?"])},
  "Publish & Push up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish & Push up"])},
  "Publish without changing updated date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish without changing updated date"])}
}