<template>
  <div class="e-notification--wrapper">
    <TransitionGroup name="e-notification">
      <div
        v-for="item in notifications"
        :key="item.id"
        class="e-notification c-pointer"
        :class="{
                'e-notification__error': item.type === 'error',
                'e-notification__warning': item.type === 'warning',
                'e-notification__success': item.type === 'success',
                }"
        @click="store.clearNotification(item.id)"
      >
        <div class="e-notification--title">
          <template v-if="item.translated">
            {{ item.title }}
          </template>
          <template v-else>
            {{ $t(item.title) }}
          </template>
        </div>
        <div class="e-notification--text">
          <template v-if="item.translated">
            {{ item.text }}
          </template>
          <template v-else>
            {{ $t(item.text) }}
          </template>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import useNotificationStore from '@/stores/notification';

const store = useNotificationStore();
const { notifications } = storeToRefs(store);
</script>
