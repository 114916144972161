import { markRaw } from 'vue';
import { defineStore } from 'pinia';

export default defineStore('modal', {
  state: () => ({
    isOpen: false,
    isFull: false,
    view: {},
    actions: [],
    title: null,
  }),
  actions: {
    open(view, actions = [], title = null) {
      this.isOpen = true;
      this.actions = actions;
      this.title = title;
      this.view = markRaw(view);
    },
    openFull(view, action, title = null) {
      this.isOpen = true;
      this.isFull = true;
      this.actions = [action];
      this.title = title;
      this.view = markRaw(view);
    },
    close() {
      this.isOpen = false;
      this.view = {};
      this.actions = [];
      this.title = null;
      this.isFull = false;
    },
  },
});
