export default {
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "Enodo Applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enodo Applications"])},
  "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "Help center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre d'aide"])},
  "Select an organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une organisation"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
  "Posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
  "Media Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Librarie de médias"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "Audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
  "Author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
  "Brief notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief notes"])},
  "Brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief"])},
  "Canonical & hreflang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canonical & hreflang"])},
  "Canonical URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL Canonique"])},
  "Caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Légende"])},
  "Clear format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le formatage"])},
  "Click to add audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer pour ajouter un audio"])},
  "Click to add image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer pour ajouter une image"])},
  "Click to add media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer pour ajouter un media"])},
  "Click to add video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer pour ajouter une video"])},
  "Credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédits"])},
  "Credits:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédits :"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "Edit keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer les mots clés"])},
  "Edit notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer les notes"])},
  "Edit options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer les options"])},
  "Embed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embed"])},
  "Enter canonical URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer l'URL canonique"])},
  "Enter hreflang URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer l'URL hreflang"])},
  "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
  "Featured image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image à la Une"])},
  "Gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie"])},
  "HTML Embed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embed HTML"])},
  "Headings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titres"])},
  "Hreflang URLs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URLs Hreflang"])},
  "Iframe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iframe"])},
  "Keyphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot clé principal"])},
  "Keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots clés"])},
  "Links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens"])},
  "List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste"])},
  "Media Gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie de médias"])},
  "Movie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidéo"])},
  "New hreflang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau hreflang"])},
  "No hreflang added yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun hreflang ajouté"])},
  "No keyphrase defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun mot clé principal défini"])},
  "No keywords defined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun mot clé défini"])},
  "No notes available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune note disponible"])},
  "Not synchronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non synchronisé"])},
  "Ordered li.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste ord."])},
  "Page break": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saut de P."])},
  "Paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paragraphe"])},
  "Paragraphs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paragraphes"])},
  "Picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
  "Publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier"])},
  "Quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citation"])},
  "Quoted from (URL)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cité de (URL)"])},
  "Replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer"])},
  "Reversed li.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste inver."])},
  "Revisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révisions"])},
  "SEO score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score SEO"])},
  "SEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEO"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "Saving...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement..."])},
  "Select locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectionner la locale"])},
  "Selected item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élément sélectionné"])},
  "Source title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de la source"])},
  "Synchronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisé"])},
  "Title 2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre 2"])},
  "Title 3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre 3"])},
  "Title 4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre 4"])},
  "Title 5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre 5"])},
  "Title 6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre 6"])},
  "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
  "Tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils"])},
  "Well done, everything seems ok!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bravo, tous les signaux sont au vert !"])},
  "Words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots"])},
  "character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caractère"])},
  "characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caractères"])},
  "word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mot"])},
  "words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mots"])},
  "import x medias": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Importer les médias"]), _normalize(["Importer ", _interpolate(_named("n")), " média"]), _normalize(["Importer ", _interpolate(_named("n")), " médias"])])},
  "x medias have been imported": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun média n'a été importé"]), _normalize(["Un média a été importé"]), _normalize([_interpolate(_named("n")), " médias ont été importés"])])},
  "Keyphrase 'keyphrase' must be in title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keyphrase '", _interpolate(_named("keyphrase")), "' must be in title"])},
  "Keyphrase 'keyphrase' must be in description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keyphrase '", _interpolate(_named("keyphrase")), "' must be in description"])},
  "n keywords are present in paragraph": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No keywords are present in paragraph"]), _normalize([_interpolate(_named("n")), " keyword is present in paragraph"]), _normalize([_interpolate(_named("n")), " keywords are present in paragraph"])])},
  "No enough titles (<n titles)": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No enough titles (<", _interpolate(_named("n")), " title)"]), _normalize(["No enough titles (<", _interpolate(_named("n")), " titles)"])])},
  "No enough words (<n words)": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No enough words (<", _interpolate(_named("n")), " words)"])},
  "Too much words (>n words)": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Too much words (>", _interpolate(_named("n")), " words)"])},
  "Publish on :site": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Publier sur ", _interpolate(_named("siteName"))])},
  "Last edit: :date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dernière modification : ", _interpolate(_named("date"))])},
  "Bullet list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste à puces"])},
  "Bullet list /short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste à pu."])},
  "Ordered list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste ordonnée"])},
  "Reversed list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste inversée"])},
  "Privacy policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
  "Terms of service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])},
  "Do nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne rien faire"])},
  "Publish now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier maintenant"])},
  "Your article is published, would you like to post your modifications online?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre article est publié, souhaitez vous mettre en ligne vos modifications ?"])},
  "Publish & Push up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier & Remonter en haut"])},
  "Publish without changing updated date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier sans changer la date de modification"])}
}