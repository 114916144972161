import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import { Integrations } from '@sentry/tracing';
import { createMetaManager } from 'vue-meta';
import App from './App';
import router from './router';
import i18n from './i18n';
import AppLayout from './layouts/AppLayout';

const version = process.env.VUE_APP_VERSION;
const name = process.env.VUE_APP_NAME;
const release = `${name}@${version}+${process.env.VUE_APP_RELEASE_COMMIT || 'dev'}`;
const isDev = process.env.NODE_ENV === 'development';

const app = createApp(App);

Sentry.init({
  app,
  release,
  environment: process.env.NODE_ENV || 'development',
  dsn: isDev ? null : 'https://9488894e1da44990dbf71fa3f0130916@o1072249.ingest.sentry.io/4506179084353536',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['semantic.enodo.app', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.NODE_ENV === 'production ' ? 0.5 : 1.0,
});

Sentry.attachErrorHandler(app, { logErrors: true });

app
  .use(createPinia())
  .use(i18n)
  .use(router)
  .use(createMetaManager())
  .component('AppLayout', AppLayout)
  .mount('#app');
