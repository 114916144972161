<template>
  {{ username }}
</template>

<script>
import { directive } from 'vue-tippy';
import useUsersStore from '@/stores/users';

export default {
  setup() {
    const usersStore = useUsersStore();

    return {
      usersStore,
    };
  },
  name: 'UserAvatar',
  directives: { tippy: directive },
  props: {
    userId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      staticUrl: process.env.VUE_APP_STATIC_URL,
      username: '',
    };
  },
  async mounted() {
    await this.usersStore.fetchUser(this.userId);
    this.username = `@${this.usersStore.getUser(this.userId).attributes.username}`;
  },
};
</script>
