<template lang="html">
  <component :is="element">
    <slot></slot>
  </component>
</template>

<script>
export default {
  props: {
    element: {
      type: String,
      required: false,
      default: 'div',
    },
    lock: {
      type: Boolean,
      required: false,
      default: false,
    },
    once: {
      type: Boolean,
      required: false,
      default: true,
    },
    checkVerticalViewport: {
      type: Boolean,
      required: false,
      default: true,
    },
    checkHorizontalViewport: {
      type: Boolean,
      required: false,
      default: false,
    },
    scrollContainer: {
      required: false,
      default: false,
    },
    instant: {
      type: Boolean,
      required: false,
      default: false,
    },
    offset: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      inView: false,
      container: null,
      observer: false,
    };
  },
  methods: {
    bindEvents() {
      const passiveSupported = this.$store.state.isPassiveEventsSupported;

      this.container.addEventListener('scroll', this.handler, passiveSupported ? { passive: true } : false);
      window.addEventListener('resize', this.handler, passiveSupported ? { passive: true } : false);
    },
    unbindEvents() {
      if (this.instant) {
        return;
      }

      this.container.removeEventListener('scroll', this.handler);
      window.removeEventListener('resize', this.handler);
    },
    handler() {
      const element = this.$el;
      const isInViewPort = this.isAnyPartOfElementInViewport(element);

      if (isInViewPort) {
        this.inView = true;
        this.$emit('inView');
        this.unbindEvents();
      }
    },
    isAnyPartOfElementInViewport(element) {
      const rect = element.getBoundingClientRect();

      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const windowWidth = window.innerWidth || document.documentElement.clientWidth;

      const isInVerticalViewport = (!this.checkVerticalViewport)
        || ((rect.top <= windowHeight) && ((rect.top + rect.height) >= 0));
      const isInHorizontalViewport = (!this.checkHorizontalViewport)
        || ((rect.left <= windowWidth) && ((rect.left + rect.width) >= 0));

      return isInVerticalViewport && isInHorizontalViewport;
    },
  },
  created() {
    if (this.instant) {
      this.inView = true;
    }
  },
  mounted() {
    if (this.instant) {
      this.inView = true;
      return;
    }

    this.container = (this.scrollContainer
      && document.querySelector(this.scrollContainer)) || document;

    if (!('IntersectionObserver' in window)) {
      this.bindEvents();
      this.handler();
      return;
    }

    const options = {};

    if (this.offset) {
      options.rootMargin = this.offset;
    }

    this.observer = new IntersectionObserver((entries) => {
      const entry = entries[0];

      if (!entry.isIntersecting) {
        return;
      }

      this.inView = true;
      this.$emit('inView');

      this.observer.disconnect();
    }, options);

    this.observer.observe(this.$el);
  },
  unmounted() {
    if (this.instant) {
      return;
    }

    if (!('IntersectionObserver' in window)) {
      this.unbindEvents();
      return;
    }

    this.observer.disconnect();
  },
};
</script>

<style lang="scss">
</style>
