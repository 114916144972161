import { defineStore } from 'pinia';
import { v4 as uuid } from 'uuid';

export default defineStore('notification', {
  state: () => ({ notifications: [] }),
  actions: {
    notify(notification) {
      const $notification = notification;
      $notification.id = uuid();
      $notification.timeoutID = setTimeout(() => this.clearNotification($notification.id), 3000);

      this.notifications.push($notification);
    },
    clearNotification(id) {
      const idx = this.notifications.findIndex((item) => item.id === id);
      if (idx >= 0) {
        clearTimeout(this.notifications[idx].timeoutID);
        this.notifications.splice(idx, 1);
      }
    },
  },
  getters: {
    getNotifications: (state) => state.notifications,
  },
});
