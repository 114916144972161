import isPlainObject from 'lodash/isPlainObject';
import { v4 as uuid } from 'uuid';

class SSO {
  constructor(config) {
    this.actions = {};
    this.isLoad = null;

    this.init(config);
  }

  init(config) {
    this.prepareConfig(config);
    this.prepareListener();

    this.element = this.prepareElement();

    this.injectElement();
  }

  prepareConfig($config) {
    const config = isPlainObject($config) ? $config : {};

    this.id = config.id || 'enodo-sso';
    this.origin = config.origin || process.env.VUE_APP_SSO_URL;
    this.src = `${this.origin}/tunnel.html`;
  }

  prepareListener() {
    window.addEventListener('message', this.listener.bind(this), this.origin);
  }

  prepareElement() {
    const element = document.createElement('iframe');

    element.id = this.id;
    element.src = this.src;
    element.width = '0';
    element.height = '0';
    element.style.display = 'none';

    return element;
  }

  listener(event) {
    if (event.origin !== this.origin) {
      return;
    }

    const { data } = event;

    if (!data) {
      return;
    }

    const { id } = data;
    const action = this.actions[id];

    if (typeof action === 'function') {
      action(data);
    }

    delete this.actions[id];
  }

  injectElement() {
    this.isLoad = new Promise((resolve, reject) => {
      const { element } = this;

      const timeout = setTimeout(() => {
        reject();
      }, 8000);

      element.onload = () => {
        setTimeout(() => {
          clearTimeout(timeout);
          resolve();
        }, 20);
      };

      element.onerror = (e) => {
        reject(e);
      };

      document.body.appendChild(element);
    });
  }

  async dispatch(name, payload) {
    await this.isLoad;

    const { contentWindow } = this.element;

    const id = uuid();

    return new Promise((resolve, reject) => {
      const action = (data) => {
        if (data.error) {
          reject(data.error);
          return;
        }

        resolve(data.payload);
      };

      this.actions[id] = action;

      try {
        contentWindow.postMessage(
          {
            id,
            name,
            payload,
          },
          this.origin,
        );
      } catch (e) {
        reject(e);
      }
    });
  }
}

export default new SSO();
