import axios from 'axios';

import SSO from './SSO';

function b64DecodeUnicode(str) {
  return decodeURIComponent(atob(str).replace(/(.)/g, (m, p) => {
    let code = p.charCodeAt(0).toString(16).toUpperCase();
    if (code.length < 2) {
      code = `0${code}`;
    }
    return `%${code}`;
  }));
}

let rt = null;
let fbnc = false;

const Iam = {
  async identify(redirect = true) {
    try {
      const anchor = window.location.hash.substring(1);
      if (anchor.startsWith('nonce=')) {
        const nonce = anchor.replace('nonce=', '');

        setTimeout(() => { window.location.hash = ''; });
        try {
          const r = await axios.patch(
            `${process.env.VUE_APP_IAM_API}/v1/me/relationships/jwt`,
            {},
            {
              headers: {
                Authorization: `Nonce ${nonce}`,
              },
            },

          );

          rt = r.data.data.attributes.refreshToken;
          fbnc = true;
          return {
            current: {
              decoded: JSON.parse(b64DecodeUnicode(r.data.data.attributes.accessToken.split('.')[1])),
              accessToken: r.data.data.attributes.accessToken,
            },
            sessions: [],
          };
        } catch (e) {
          console.error(e);
        }
      }

      if (fbnc) {
        try {
          const r = await axios.patch(
            `${process.env.VUE_APP_IAM_API}/v1/me/relationships/jwt`,
            {},
            {
              headers: {
                Authorization: `Bearer ${rt}`,
              },
            },

          );

          return {
            current: {
              decoded: JSON.parse(b64DecodeUnicode(r.data.data.attributes.accessToken.split('.')[1])),
              accessToken: r.data.data.attributes.accessToken,
            },
            sessions: [],
          };
        } catch (e) {
          console.error(e);
        }
      }

      const sess = await SSO.dispatch('getSessions');

      const token = await SSO.dispatch('getAccessToken', sess.current);
      const decoded = JSON.parse(b64DecodeUnicode(token.split('.')[1]));

      return {
        current: {
          decoded,
          accessToken: token,
        },
        sessions: sess.sessions.map((s) => {
          const user = sess.users.find((u) => u.id === s.id);
          return {
            ...user,
            ...s,
          };
        }),
      };
    } catch (e) {
      if (redirect && !fbnc) {
        window.location.href = `${
          process.env.VUE_APP_SSO_URL
        }/?${e === 'NOT_LOGGED_IN' ? 'ce=1&' : ''}redirect=${encodeURIComponent(document.location.href.replace(window.location.hash, ''))}`;
      } else {
        console.error(e);
        if (e !== 'TIMED_OUT') {
          alert('SESSION_EXPIRED');
        }
      }
      throw e;
    }
  },

  async getConvergence() {
    const convergence = await SSO.dispatch('getconvergence');

    return convergence.convergence_token;
  },
};

export default Iam;
