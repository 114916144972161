<template>
<section class="home">
  <CreateArticle
    ref="createArticleModal"
    @created="onCreateArticle"
    />

  <header class="ai-center pb8 d-flex px7 py6 bb bc-light-050">
    <h1 class="fs-15">{{ $t('Recently opened') }}</h1>
    <span class="fl1" />
    <span
      @click="$refs.createArticleModal.open()"
      class="e-btn e-btn__iconified d-none sm:d-flex"
    >
      <icon-create-article/>
      {{ $t('New article') }}
    </span>
  </header>

  <div v-if="!documentsLoaded" class="d-flex ai-center jc-center fc-light-300 fs-13 px7 py6">
    {{ $t('Loading ...') }}
  </div>
  <div
    v-else
    class="px4 pb6"
    >
    <table
      class="e-table pt2"
      >
      <thead class="e-table--header">
        <tr>
          <th class="w100">
            <span class="e-table--headtitle">
              {{ $t('Title') }}
            </span>
          </th>
          <th class="d-none md:d-table-cell">
            <span class="e-table--headtitle">
              {{ $t('Owner') }}
            </span>
          </th>
          <th class="d-none md:d-table-cell">
            <span class="e-table--headtitle">
              {{ $t('Last opened by me') }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="document in documents.data"
          v-bind:key="document.id"
          class="e-table--row ps-relative"
          >
          <td class="fw-500 va-center">
            <div class="d-flex ai-center">
              <router-link
                :to="{ name: 'EditArticle', params: { documentId: document.id } }"
                class="e-relative-link d-flex ai-center jc-end"
              >
                <icon-article class="mr2" />
                {{document.attributes.name}}
              </router-link>
            </div>
          </td>
          <td class="d-none md:d-table-cell">
            <user-name :user-id="document.attributes.ownerId" />
          </td>
          <td class="d-none md:d-table-cell whitespace-nowrap">
            <template v-if="document.attributes.openedAt">
              {{ $d(document.attributes.openedAt, 'long')  }}
            </template>
            <template v-else>
              {{ $t('N/A') }}'
            </template>
          </td>
        </tr>
        <in-view
          @inView="loadMore"
          element="tr"
          :once="true"
          v-if="documentsLoaded && documents.links.next?.length"
          v-bind:key="documents.links.next"
          >
          <td colspan="3" class="ta-center fc-light-300 fs-13">
            {{ $t('Loading more ...') }}
          </td>
        </in-view>
      </tbody>
    </table>
  </div>
</section>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useActiveMeta } from 'vue-meta';
import IconCreateArticle from '@/components/icons/IconCreateArticle';
import IconArticle from '@/components/icons/IconArticle';
import useIamStore from '@/stores/iam';
import UserName from '@/components/UserName';
import InView from '@/components/InView';
import CreateArticle from '@/components/Semantic/Create';

const { t } = useI18n({ useScope: 'global' });
const meta = useActiveMeta();
meta.title = t('Home');

const router = useRouter();
const iamStore = useIamStore();
const documentsLoaded = ref(Boolean(false));

const documents = ref({ data: [], links: {}, included: [] });

const loadMore = async () => {
  if (!documents.value.links?.next) {
    return false;
  }
  const accessToken = iamStore.getAccessToken;
  const res = await axios.get(
    `${process.env.VUE_APP_FS_API}${documents.value.links.next}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  documents.value.data = documents.value
    .data.concat(res.data.data);
  documents.value.included = documents.value
    .included.concat(res.data.included);
  documents.value.links = res.data.links;
  return true;
};

const onCreateArticle = (document) => {
  router.push({ name: 'EditArticle', params: { documentId: document.id } });
};

onMounted(async () => {
  const accessToken = iamStore.getAccessToken;
  const res = await axios.get(
    `${process.env.VUE_APP_FS_API}/v1/recents/?filter[type]=semantic.article`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  documents.value = res.data;
  documentsLoaded.value = true;
});
</script>
