<template>
<transition name="e-modal" :duration="300">
  <aside
    v-if="isOpen"
    class="e-modal e-modal__full">
    <OnClickOutside
      @trigger="closeModal"
      class="e-modal--dialog overflow-visible"
      >
      <header class="e-modal--header pb6 wmx100 ws9">
        <h2 class="mr4">{{ $t('New article') }}</h2>
        <icon-cross-light
          width="20"
          height="20"
          class="c-pointer fc-light-400"
          @click="closeModal"
          />
      </header>

      <form @submit.prevent="createArticle">
        <input
          class="e-input wmn6 wmx100 ba bc-light-075 bar-md e-input__lg fs-14 px2 w100"
          type="text"
          v-model="title"
          :placeholder="$t('Enter title of your article')"
        />

      <footer class="e-modal--footer pt6">
        <button
          class="e-btn"
          :class="{ 'o50': isSaving }"
          :disabled="isSaving"
          type="submit"
          >
            {{ $t('Create') }}
        </button>
        <span
          class="e-btn e-btn__tertiary"
          @click="closeModal"
          >
          {{ $t('Cancel') }}
        </span>
      </footer>
      </form>
    </OnClickOutside>
  </aside>
</transition>
</template>

<script setup>
import {
  ref,
  defineExpose,
  defineEmits,
} from 'vue';
import { OnClickOutside } from '@vueuse/components';
import axios from 'axios';
import useIamStore from '@/stores/iam';
import useNotificationStore from '@/stores/notification';

const emit = defineEmits(['created']);
const iamStore = useIamStore();
const notificationStore = useNotificationStore();
const isOpen = ref(false);

const title = ref('');

const openModal = () => {
  title.value = '';
  isOpen.value = true;
};

const closeModal = () => {
  isOpen.value = false;
};

const createArticle = async () => {
  try {
    const accessToken = iamStore.getAccessToken;
    const res = await axios.post(
      `${process.env.VUE_APP_FS_API}/v1/documents`,
      {
        data: {
          attributes: {
            name: title.value.length ? title.value : 'Unnamed post',
            mimetype: 'application/enodo.semantic.article+json',
            createdFrom: 'semantic',
          },
        },
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    emit('created', res.data.data);
    closeModal();
  } catch (e) {
    console.error(e);
    notificationStore.notify({
      title: e.response.data.errors[0].title,
      text: e.response.data.errors[0].detail,
      type: 'error',
    });
  }
};

defineExpose({
  open: openModal,
  close: closeModal,
});
</script>
