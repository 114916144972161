import { createRouter, createWebHistory } from 'vue-router';
import qs from 'qs';
import Home from '../views/Home';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      layout: 'AppLayoutDefault',
    },
  },
  {
    path: '/article/:documentId',
    name: 'EditArticle',
    component: () => import(/* webpackChunkName: "article" */ '../views/Article'),
    meta: {
      layout: 'AppLayoutRaw',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  parseQuery: qs.parse,
  stringifyQuery: (query) => qs.stringify(query, { encode: false }),
});

export default router;
