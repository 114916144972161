<template>
  <div class="e-loader bg-light ps-absolute t0 r0 b0 l0 d-flex ai-center jc-center">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1123 146"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="fill-rule:evenodd;
             clip-rule:evenodd;
             stroke-linecap:round;
             stroke-linejoin:round;
             stroke-miterlimit:1.5;"
    >
      <g transform="matrix(0.842397,0,0,0.842397,-2339.67,-194.054)">
        <path
          d="M2779.38,284.906C2852.6,266.593
             2936.87,248.288
             3011.81,238.864C3143.13,222.35
             3245.79,233.109
             3210.06,318.898C3146.22,472.196
             3498.77,367.656
             3549.55,341.062C3575.5,327.473
             3558.03,309.415
             3538.97,318.898C3533.83,321.454
             3527.65,330.06 3528.23,341.935C3529.1,359.968
             3545.53,393.403 3652.14,381.34C3776.4,367.279
             4009.73,289.155 4107.75,278.154"
          style="fill:none;stroke:rgb(74,221,159);stroke-width:3.96px;"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style scoped lang="scss">
.e-loader {
  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity 1s ease;
  }

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }
  svg path {
    stroke-dasharray: 1610;
    stroke-dashoffset: 1610;
    animation: dash 4s linear infinite;
  }
  &.fade-leave-active svg path {
    animation: none;
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  from {
    stroke-dashoffset: 1610;
  }
  to {
    stroke-dashoffset: -1610;
  }
}
</style>
