<template>
<metainfo>
  <template
    v-slot:title="{ content }"
  >{{ content ? `${content} | ${appTitle}` : appTitle }}</template>
</metainfo>

<transition name="fade">
  <Loader id="await-login" v-if="!logged">
  </Loader>
</transition>

<AppLayout v-if="logged">
  <Modal />
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</AppLayout>

<Notification />

</template>

<script setup>
import {
  ref, onMounted, watch,
} from 'vue';
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import AppLayout from '@/layouts/AppLayout';
import Loader from '@/components/Loader';
import Modal from '@/components/Modal';
import Notification from '@/components/Notification';
import useIamStore from '@/stores/iam';
import useModalStore from '@/stores/modal';

const route = useRoute();
const logged = ref(false);
const user = ref(Object(null));
const appTitle = process.env.VUE_APP_TITLE;
const lang = ref(String('en'));
const iamStore = useIamStore();
const modalStore = useModalStore();
const { locale } = useI18n({ useScope: 'global' });
const meta = useMeta({
  title: 'Semantic',
  htmlAttrs: { lang: lang.value, dir: 'ltr' },
  link: [
    // {
    //   rel: 'stylesheet',
    //   href: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap',
    // },
  ],
});

watch(route, async () => {
  modalStore.close();
});

onMounted(async () => {
  await iamStore.identify();
  const $user = iamStore.getUser;
  logged.value = $user !== null;
  user.value = $user;
  if (logged.value && user.value) {
    locale.value = user.value.attributes.locale;
    [lang.value] = user.value.attributes.locale.split('-');
    meta.meta.htmlAttrs.lang = lang.value;
  }
});

</script>

<style lang="scss">
@import 'assets/scss/style';

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
