<template>
<!-- eslint-disable -->
<svg :width="width" :height="height" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.00366 7.44266L1.28366 0.723656C0.991656 0.430656 0.515656 0.430656 0.223656 0.723656C-0.0693438 1.01566 -0.0693438 1.49166 0.223656 1.78366L6.94266 8.50366L0.223656 15.2237C-0.0693438 15.5157 -0.0693438 15.9917 0.223656 16.2837C0.515656 16.5767 0.991656 16.5767 1.28366 16.2837L8.00366 9.56466L14.7237 16.2837C15.0157 16.5767 15.4917 16.5767 15.7837 16.2837C16.0767 15.9917 16.0767 15.5157 15.7837 15.2237L9.06466 8.50366L15.7837 1.78366C16.0767 1.49166 16.0767 1.01566 15.7837 0.723656C15.4917 0.430656 15.0157 0.430656 14.7237 0.723656L8.00366 7.44266Z" :fill="color"/>
</svg>
<!-- eslint-enable -->
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 17,
    },
    height: {
      type: [Number, String],
      default: 17,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
