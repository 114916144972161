import { defineStore } from 'pinia';
import axios from 'axios';
import Iam from '../helpers/Iam';

export default defineStore('iam', {
  state: () => ({
    user: null,
    token: null,
    accessToken: null,
  }),
  actions: {
    async identify(force = false) {
      if (this.user && !force) {
        return this.user;
      }

      try {
        const sso = await Iam.identify(!force);
        if (!sso) {
          this.user = null;
          this.token = null;
          this.accessToken = null;
          return false;
        }

        const me = await axios.get(
          `${process.env.VUE_APP_IAM_API}/v1/me`,
          {
            headers: {
              Authorization: `Bearer ${sso.current.accessToken}`,
            },
          },
        );
        this.user = me.data.data;
        this.token = sso.current.decoded;
        this.accessToken = sso.current.accessToken;
      } catch (e) {
        console.error('[SSO]', e);
      }
      const delay = (new Date(this.token.exp * 1000) - new Date()) - 120000 * 3; // 2min
      if (delay > 0) {
        setTimeout(async () => {
          await this.identify(true);
        }, delay);
      } else {
        await this.identify(true);
      }

      return true;
    },

    async forceRefresh() {
      await this.identify(true);
    },
  },
  getters: {
    getUser: (state) => state.user,
    getToken: (state) => state.token,
    getAccessToken: (state) => state.accessToken,
  },
});
